import Vue from "vue";
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios";
import Router from "@/router";
import Store from "@/store";
import { ErrorMessage } from "@/utils";

// import { getAuthService } from "@/auth";
// const authService = getAuthService();
class HttpClient {
  protected instance: typeof AxiosInstance;
  constructor(baseURL: string, timeout = 30000) {
    this.instance = axios.create({
      baseURL,
      timeout,
    });

    this.instance.interceptors.request.use(
      (config: typeof AxiosRequestConfig) => {
        config.headers = {
          ...config.headers,
          "Cache-Control": "no-cache",
          // Authorization: `Bearer ${Vue.$cookies.get("riq_session")}`,
          Authorization: `Bearer ${Vue.$cookies.get("riq_session")}`,
        };
        return config;
      },
      (error: Error) => {
        Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response: typeof AxiosResponse) => response,
      (error: typeof AxiosError) => {
        if (
          error.response.status &&
          (error.response.status === 401 || error.response.status === 403) &&
          !error.response.config.url.includes("/oauth/google")
        ) {
          Vue.$cookies.remove("riq_session");
          Vue.$cookies.remove("selected_order_id");
          if (!Router.currentRoute.fullPath.includes("auth")) {
            Router.push("/auth/login");
          }
        }

        const snackbar = {
          type: "error",
          message: ErrorMessage(error.response.data),
          open: true,
        };

        Store.commit("setSnackbar", snackbar);
        return Promise.reject(error);
      }
    );
  }
}

export default HttpClient;
