import Vue from "vue";
import Vuex from "vuex";
import Api from "@/api/ApiClient";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentRoute: "/",
    sidebarExpand: false,
    pageTitle: "",
    parentOrder: {},
    subOrders: [],
    selectedOrder: {},
    snackbar: {
      open: false,
      message: "",
      type: "",
    },
    loading: false,
    whiteLabel: {
      isWhiteLabelEnabled: false,
      logo: null,
      name: null,
      email: null,
      appName: null,
      phone: null,
      scheduleAppointmentLink: null,
    },
    accessControl: {},
  },
  mutations: {
    setCurrentRoute(state, val) {
      state.currentRoute = val;
    },
    setSidebarExpand(state, val) {
      state.sidebarExpand = val;
    },
    setParentOrder(state, val) {
      state.parentOrder = val;
    },
    setSubOrders(state, val) {
      state.subOrders = val;
    },
    setSelectedOrder(state, val) {
      state.selectedOrder = val;
    },
    setPageTitle(state, val) {
      state.pageTitle = val;
    },
    setSnackbar(state, val) {
      state.snackbar = val;
    },
    setLoading(state, val) {
      state.loading = val;
    },
    setWhitelabel(state, val) {
      state.whiteLabel.isWhiteLabelEnabled = val.isWhiteLabelEnabled;
      state.whiteLabel.appName = val.appName;
      state.whiteLabel.logo = val.logo;
      state.whiteLabel.name = val.name;
      state.whiteLabel.email = val.email;
      state.whiteLabel.phone = val.phone;
      state.whiteLabel.scheduleAppointmentLink = val.scheduleAppointmentLink;
    },
    setAccessControl(state, val) {
      state.accessControl = val;
    },
  },
  actions: {
    async getAccount({ commit }, data) {
      try {
        commit("setLoading", true);
        const api = new Api();
        const res = await api.getAccount(data);
        if (res && res.result) {
          if (res.result) {
            const { subOrders, accessControl, ...parentData } = res.result;
            commit("setParentOrder", parentData);
            commit("setSubOrders", subOrders);
            commit("setSelectedOrder", parentData);
            commit("setAccessControl", accessControl);
            return res.result;
          }
        }
      } finally {
        commit("setLoading", false);
      }
    },
  },
  modules: {},
});
