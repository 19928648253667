import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/style.css";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
// import { Auth0Plugin } from "./auth";
import VCalendar from "v-calendar";
import { Dropdown, Tooltip, Menu } from "floating-vue";
import { upperFirst, camelCase } from "lodash";
import VueCookies from "vue-cookies";
import Vuelidate from "vuelidate";

import "floating-vue/dist/style.css";

const components = require.context(
  // The relative path of the components folder
  "@/components/common",
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /[A-Z]\w+\.(vue|js)$/
);

components.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = components(fileName);

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Strip the leading `./` and extension from the filename
      fileName.replace(/^\.\/(.*)\.\w+$/, "$1")
    )
  );
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  );
});

// Vue.use(Auth0Plugin, {
//   domain: process.env.VUE_APP_RIQ_AUTH0_DOMAIN,
//   clientId: process.env.VUE_APP_RIQ_AUTH0_CLIENT_ID,
//   audience: process.env.VUE_APP_RIQ_AUTH0_AUDIENCE,
//   onRedirectCallback: (appState: { targetUrl: string }) => {
//     router.push(
//       appState && appState.targetUrl
//         ? appState.targetUrl
//         : window.location.pathname
//     );
//   },
// });
Vue.use(Vuelidate);
Vue.use(VueCookies);
Vue.$cookies.config("2d");
Vue.use(VCalendar, {
  componentPrefix: "vc",
});

Vue.component("VDropdown", Dropdown);
Vue.component("VTooltip", Tooltip);
Vue.component("VMenu", Menu);

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_RIQ_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [
        "localhost",
        "staging.reviewiq.expertreputation.com",
        "beta.reviewiq.expertreputation.com",
        /^\//,
      ],
    }),
  ],
  tracesSampleRate: 1.0,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
