import Vue from "vue";
import Store from "@/store";
import { NavigationGuard } from "vue-router";

export const postLoginGuard: NavigationGuard = (to, from, next) => {
  if (Store.state.whiteLabel.isWhiteLabelEnabled) {
    document.title = `${Store.state.whiteLabel.appName} - ${to.name}`;
  } else {
    document.title = `ReviewIQ - ${to.name}`;
  }
  Store.commit("setLoading", true);
  const selected_order_id = Vue.$cookies.get("selected_order_id");

  if (!Vue.$cookies.get("riq_session")) {
    return next(`/auth/login`);
  } else {
    if (selected_order_id !== to.params.oid) {
      next(`/subscription/${selected_order_id}/dashboard`);
    } else if (
      to.name === "New Subscription" ||
      to.name === "Manage Subscriptions"
    ) {
      manageSubscriptionGuard(to, from, next);
    } else {
      // return next();
      accessControlGuard(to, from, next);
    }
  }
};

export const preLoginGuard: NavigationGuard = (to, from, next) => {
  if (Store.state.whiteLabel.isWhiteLabelEnabled) {
    document.title = `${Store.state.whiteLabel.appName} - ${to.name}`;
  } else {
    document.title = `ReviewIQ - ${to.name}`;
  }
  Store.commit("setLoading", true);
  const selected_order_id = Vue.$cookies.get("selected_order_id");
  if (Vue.$cookies.get("riq_session")) {
    if (from.name === "Report Card") {
      Store.commit("setLoading", false);
    }
    return next(`/subscription/${selected_order_id}/dashboard`);
  }
  return next();
};

const manageSubscriptionGuard: NavigationGuard = (to, from, next) => {
  const selected_order_id = Vue.$cookies.get("selected_order_id");
  const is_sub_order =
    Vue.$cookies.get("is_sub_order") === "true" ? true : false;

  if (!is_sub_order && !Store.state.whiteLabel.isWhiteLabelEnabled) {
    return next();
  } else {
    if (from.name === "Report Card") {
      Store.commit("setLoading", false);
    }
    return next(`/subscription/${selected_order_id}/dashboard`);
  }
};

const accessControlGuard: NavigationGuard = (to, from, next) => {
  const accessControl = Store.state.accessControl as { yextIncluded: number };
  const selected_order_id = Vue.$cookies.get("selected_order_id");

  if (to.name === "Listings Manager") {
    if (accessControl.yextIncluded === 0) {
      return next(`/subscription/${selected_order_id}/dashboard`);
    } else {
      return next();
    }
  } else {
    return next();
  }
};
