
export default {
  watch: {
    "$route.params.oid"(val) {
      if (
        this.$cookies.get("selected_order_id") &&
        this.$cookies.get("riq_session")
      ) {
        const selected_order_id = this.$cookies.get("selected_order_id");

        if (val !== selected_order_id) {
          this.$store.commit("setSnackbar", {
            open: true,
            message: `Unable to switch to subscription ${val || ""}`,
            type: "error",
          });

          this.$router.push(`/subscription/${selected_order_id}/dashboard`);
        }
      }
    },
  },
};
