import HttpClient from "./HttpClient";
import { AxiosResponse } from "axios";
import qs from "query-string";
import { pickBy, identity } from "lodash";
import { IRequestParams, IResponse, IPaginatedResponse } from "@/typings";
import {
  IAuthLogin,
  IBlacklistInvite,
  IUpdateReviewInviteSettingsDto,
  IUserDto,
  IUserPasswordDto,
  IOrder,
  IOrderNotification,
  IOrderReferralDto,
  IOrderNotificationDeleteDto,
  IPostOrdersPaymentSubscriptionDto,
  ISendInviteDto,
  IReviewRecentStatistics,
  IReviewInviteStatistics,
  ISurveyReview,
  IOrderPaymentSubscription,
  IReviewStatistics,
  IReviewProfile,
  IReviewTrendStatistics,
  IReviewBenchmarkStatistics,
  IReviewCountStatistics,
  IHighlightedReview,
  IUserSubcription,
  IUserProfile,
  IReviewInvite,
  IReviewInviteSettings,
  IOrderProfileDto,
  IOrderDto,
  IBlacklistInviteDto,
  IMicrositeDetail,
  IMicrositeBusinessDescriptionDto,
  IMicrositeSocialMediaLinksLinksDto,
  IMicrositeAppointmentSchedulerDto,
  IHoursOfOperationOfOperationDto,
  IMicrositeBannerDto,
  IResellerConfiguration,
  IGoogleReviewResponse,
  ILocationSubscriptionDto,
  IProviderSubscriptionDto,
} from "@/interfaces";

export default class ReviewIQApiClient extends HttpClient {
  constructor() {
    super(process.env.VUE_APP_RIQ_API);
  }

  async login(username: string, password: string): Promise<IAuthLogin> {
    const url = `/auth/login`;
    try {
      const response: typeof AxiosResponse = await this.instance.post(url, {
        username: username,
        password: password,
      });
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getAccount(
    id: number,
    includeVoidedSubOrders = 0
  ): Promise<IResponse<IOrder>> {
    const url = `/orders/info?oid=${id}&includeVoidedSubOrders=${
      includeVoidedSubOrders ? 1 : 0
    }`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getUserProfile(): Promise<IResponse<IUserProfile>> {
    const url = `/users/profile`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateUserProfile(
    payload: IUserDto
  ): Promise<IResponse<null | number>> {
    const url = `/users/profile`;
    try {
      const response: typeof AxiosResponse = await this.instance.put(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateUserPassword(
    payload: IUserPasswordDto
  ): Promise<IResponse<null | number>> {
    const url = `/users/profile/change-password`;
    try {
      const response: typeof AxiosResponse = await this.instance.put(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createUser(
    params: IRequestParams,
    payload: IUserDto
  ): Promise<IResponse<null | number>> {
    const urlParams = qs.stringify(pickBy(params, identity));
    const url = `/users?${urlParams}`;
    try {
      const response: typeof AxiosResponse = await this.instance.post(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateOrder(ordersData: IOrderDto): Promise<IResponse<null | number>> {
    const url = `/orders/${ordersData.id}`;
    const param = {
      first_name: ordersData.first_name,
      middle_name: ordersData.middle_name,
      last_name: ordersData.last_name,
      suffix: ordersData.suffix,
      salutation: ordersData.salutation,
      business_name: ordersData.business_name,
      street_address: ordersData.street_address,
      suite: ordersData.suite,
      city: ordersData.city,
      state: ordersData.state,
      zip: ordersData.zip,
      country: ordersData.country,
      business_phone: ordersData.business_phone,
    };
    try {
      const response: typeof AxiosResponse = await this.instance.put(
        url,
        param
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getSurveyReviewList(
    id: number,
    page: number,
    limit: number,
    keyword: string,
    minDate: string,
    maxDate: string
  ): Promise<IPaginatedResponse<ISurveyReview>> {
    const url =
      `/survey-reviews?oid=${id}` +
      "&page=" +
      page +
      "&limit=" +
      limit +
      (keyword ? "&keyword=" + keyword : "") +
      (minDate ? "&date_from=" + minDate : "") +
      (maxDate ? "&date_to=" + maxDate : "");
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getInvites(
    id: number,
    page: number,
    limit: number,
    keyword: string,
    minDate: string,
    maxDate: string,
    clickthrough: string,
    inviteType: string
  ): Promise<IPaginatedResponse<IReviewInvite>> {
    const url =
      `/invites?oid=${id}` +
      "&page=" +
      page +
      "&limit=" +
      limit +
      (keyword ? "&keyword=" + keyword : "") +
      (minDate ? "&date_from=" + minDate : "") +
      (maxDate ? "&date_to=" + maxDate : "") +
      (clickthrough ? "&clickthrough=" + clickthrough : "") +
      (inviteType ? "&invite_type=" + inviteType : "");
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getReviewStatistic(
    params: IRequestParams
  ): Promise<IResponse<IReviewStatistics>> {
    const urlParams = qs.stringify(pickBy(params, identity));
    const url = `/reviews/statistics?${urlParams}`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getRecentReviewStatistic(
    params: IRequestParams
  ): Promise<IResponse<IReviewRecentStatistics>> {
    const urlParams = qs.stringify(pickBy(params, identity));
    const url = `/reviews/statistics/recent?${urlParams}`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getInvitePerformanceStatistics(
    params: IRequestParams
  ): Promise<IReviewInviteStatistics> {
    const urlParams = qs.stringify(pickBy(params, identity));
    const url = `/reviews/statistics/invites?${urlParams}`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getReviewProfiles(
    params: IRequestParams
  ): Promise<IResponse<IReviewProfile>> {
    const urlParams = qs.stringify(pickBy(params, identity));
    const url = `/profiles?${urlParams}`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createReviewProfile(
    oid: number,
    payload: IOrderProfileDto
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${oid}/profiles`;
    try {
      const response: typeof AxiosResponse = await this.instance.post(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteReviewProfile(
    oid: number,
    profileId: number
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${oid}/profiles/${profileId}`;
    try {
      const response: typeof AxiosResponse = await this.instance.delete(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getSubscriptions(): Promise<IResponse<IUserSubcription>> {
    const url = `/users/profile/subscriptions`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getNotificationEmails(
    oid: number
  ): Promise<IResponse<IOrderNotification>> {
    const url = `/orders/${oid}/notification-emails`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getReviewInviteSettings(
    params: IRequestParams
  ): Promise<IResponse<IReviewInviteSettings>> {
    const urlParams = qs.stringify(pickBy(params, identity));
    const url = `/review-invite-settings?${urlParams}`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getReviewInviteBlocklist(
    params: IRequestParams
  ): Promise<IPaginatedResponse<IBlacklistInvite>> {
    const urlParams = qs.stringify(pickBy(params, identity));
    const url = `/invites/blacklist?${urlParams}`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createReviewInviteBlocklist(
    params: IRequestParams,
    payload: IBlacklistInviteDto
  ): Promise<IResponse<null | number>> {
    const urlParams = qs.stringify(pickBy(params, identity));
    const url = `/invites/blacklist?${urlParams}`;
    try {
      const response: typeof AxiosResponse = await this.instance.post(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteReviewInviteBlocklist(
    id: number,
    params: IRequestParams
  ): Promise<IResponse<null | number>> {
    const urlParams = qs.stringify(pickBy(params, identity));
    const url = `/invites/blacklist/${id}?${urlParams}`;
    try {
      const response: typeof AxiosResponse = await this.instance.delete(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateReviewInviteSettings(
    params: IRequestParams,
    payload: IUpdateReviewInviteSettingsDto
  ): Promise<IResponse<null | number>> {
    const urlParams = qs.stringify(pickBy(params, identity));
    const url = `/review-invite-settings?${urlParams}`;
    try {
      const response: typeof AxiosResponse = await this.instance.put(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getBlockedInvitations(
    params: IRequestParams
  ): Promise<IPaginatedResponse<IBlacklistInvite>> {
    const urlParams = qs.stringify(pickBy(params, identity));
    const url = `/invites/blocked-invites?${urlParams}`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getReviewInviteBlockingOptions(
    params: IRequestParams
  ): Promise<IPaginatedResponse<IBlacklistInvite>> {
    const urlParams = qs.stringify(pickBy(params, identity));
    const url = `/invites/blocking-options?${urlParams}`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateReviewBlockingOptions(
    params: IRequestParams,
    payload: IBlacklistInviteDto
  ): Promise<IResponse<null | number>> {
    const urlParams = qs.stringify(pickBy(params, identity));
    const url = `/invites/blocking-options/?${urlParams}`;
    try {
      const response: typeof AxiosResponse = await this.instance.put(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getTrendStatistic(
    id: number,
    dateFrom: string,
    dateTo: string
  ): Promise<IResponse<IReviewTrendStatistics>> {
    const url =
      `/reviews/statistics/trends?oid=${id}` +
      (dateFrom ? "&date_from=" + dateFrom : "") +
      (dateTo ? "&date_to=" + dateTo : "");
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getBenchmarkStatistic(
    id: number,
    dateFrom: string,
    dateTo: string
  ): Promise<IResponse<IReviewBenchmarkStatistics>> {
    const url =
      `/reviews/statistics/benchmark?oid=${id}` +
      (dateFrom ? "&date_from=" + dateFrom : "") +
      (dateTo ? "&date_to=" + dateTo : "");
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async postInvite(
    params: IRequestParams,
    payload: ISendInviteDto
  ): Promise<IResponse<null | number>> {
    const urlParams = qs.stringify(pickBy(params, identity));
    const url = `/invites?${urlParams}`;
    try {
      const response: typeof AxiosResponse = await this.instance.post(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getReviewsCountStatistics(
    oid: number,
    groupBy: string
  ): Promise<IResponse<IReviewCountStatistics>> {
    const url = `/reviews/statistics/count?oid=${oid}&groupBy=${groupBy}`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getReviewList(
    id: number,
    page: number,
    limit: number,
    keyword: string,
    minDate: string,
    maxDate: string,
    minRating: number,
    maxRating: number,
    sitesId: number
  ): Promise<IResponse<IReviewRecentStatistics>> {
    const url =
      `/reviews?oid=${id}` +
      "&page=" +
      page +
      "&limit=" +
      limit +
      (minRating ? "&min_rating=" + minRating : "") +
      (maxRating ? "&max_rating=" + maxRating : "") +
      (keyword ? "&review_content=" + keyword : "") +
      (minDate ? "&date_from=" + minDate : "") +
      (maxDate ? "&date_to=" + maxDate : "") +
      (sitesId ? "&site_ids[0]=" + sitesId : "");

    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getHighlightedReviews(
    params: IRequestParams
  ): Promise<IResponse<IHighlightedReview>> {
    const urlParams = qs.stringify(pickBy(params, identity));
    const url = `/reviews/highlighted?${urlParams}`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateHighlightedReviews(
    params: IRequestParams,
    id: number
  ): Promise<IResponse<null | number>> {
    const urlParams = qs.stringify(pickBy(params, identity));
    const url = `/reviews/highlighted/${id}?${urlParams}`;
    try {
      const response: typeof AxiosResponse = await this.instance.put(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async forgotPassword(username: string): Promise<IResponse<null | number>> {
    const url = "/password/forgot";
    try {
      const response: typeof AxiosResponse = await this.instance.post(url, {
        username: username,
      });
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async resetPassword(
    ref: string,
    newPassword: string,
    confirmPassword: string
  ): Promise<IResponse<null | number>> {
    const url = "/password/reset";

    try {
      const response: typeof AxiosResponse = await this.instance.post(url, {
        ref: ref,
        new_password: newPassword,
        confirm_password: confirmPassword,
      });
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async validatePasswordResetLink(
    ref: string
  ): Promise<IResponse<null | number>> {
    const url = `/password/reset/check/${ref}`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async postReferral(
    oid: number,
    payload: IOrderReferralDto
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${oid}/referral`;
    try {
      const response: typeof AxiosResponse = await this.instance.post(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getPaymentSubscription(
    id: number
  ): Promise<IResponse<IOrderPaymentSubscription>> {
    const url = `/orders/${id}/payment/subscription`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async postPaymentSubscription(
    id: number,
    payload: IPostOrdersPaymentSubscriptionDto
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/payment/subscription`;
    try {
      const response: typeof AxiosResponse = await this.instance.post(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async downloadDekstopReviewLink(oid: number): Promise<HTMLElement> {
    const url = `/orders/${oid}/desktop-review-link/download`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async exportReviews(
    oid: number,
    format: string,
    keyword: string,
    minDate: string,
    maxDate: string,
    minRating: number,
    maxRating: number,
    sitesId: number
  ): Promise<Blob> {
    const url =
      `/reviews/download?oid=${oid}&format=${format}` +
      (minRating ? "&min_rating=" + minRating : "") +
      (maxRating ? "&max_rating=" + maxRating : "") +
      (keyword ? "&review_content=" + keyword : "") +
      (minDate ? "&date_from=" + minDate : "") +
      (maxDate ? "&date_to=" + maxDate : "") +
      (sitesId ? "&site_ids[0]=" + sitesId : "");
    const responseType =
      format !== "csv"
        ? {
            responseType: "blob",
          }
        : {};
    try {
      const response: typeof AxiosResponse = await this.instance.get(
        url,
        responseType
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async exportSurveyReviews(
    oid: number,
    format: string,
    keyword: string,
    minDate: string,
    maxDate: string
  ): Promise<Blob> {
    const url =
      `/survey-reviews/download?oid=${oid}&format=${format}` +
      (keyword ? "&keyword=" + keyword : "") +
      (minDate ? "&date_from=" + minDate : "") +
      (maxDate ? "&date_to=" + maxDate : "");
    const responseType =
      format !== "csv"
        ? {
            responseType: "blob",
          }
        : {};
    try {
      const response: typeof AxiosResponse = await this.instance.get(
        url,
        responseType
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async exportInvites(
    oid: number,
    format: string,
    keyword: string,
    minDate: string,
    maxDate: string,
    clickthrough: string,
    inviteType: string
  ): Promise<Blob> {
    const url =
      `/invites/download?oid=${oid}&format=${format}` +
      (keyword ? "&keyword=" + keyword : "") +
      (minDate ? "&date_from=" + minDate : "") +
      (maxDate ? "&date_to=" + maxDate : "") +
      (clickthrough ? "&clickthrough=" + clickthrough : "") +
      (inviteType ? "&invite_type=" + inviteType : "");
    const responseType =
      format !== "csv"
        ? {
            responseType: "blob",
          }
        : {};
    try {
      const response: typeof AxiosResponse = await this.instance.get(
        url,
        responseType
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async addNotificationEmail(
    oid: number,
    email: string
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${oid}/notification-email`;
    try {
      const response: typeof AxiosResponse = await this.instance.put(url, {
        email,
      });
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteNotificationEmail(
    oid: number,
    email: string
  ): Promise<IOrderNotificationDeleteDto> {
    const url = `/orders/${oid}/notification-email`;
    try {
      const response: typeof AxiosResponse = await this.instance.delete(url, {
        data: {
          email,
        },
      });
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async postBulkInvites(
    oid: number,
    payload: ISendInviteDto[]
  ): Promise<IResponse<null | number>> {
    const url = `/invites/bulk?oid=${oid}`;
    try {
      const response: typeof AxiosResponse = await this.instance.post(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getMicrositeDetail(id: number): Promise<IResponse<IMicrositeDetail>> {
    const url = `/orders/${id}/micro-site/detail`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateMicrositeBusinessDescription(
    id: number,
    payload: IMicrositeBusinessDescriptionDto
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/micro-site/business-description`;
    try {
      const response: typeof AxiosResponse = await this.instance.put(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateMicrositeSocialMediaLinksLinks(
    id: number,
    payload: IMicrositeSocialMediaLinksLinksDto
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/micro-site/social-media-links`;
    try {
      const response: typeof AxiosResponse = await this.instance.put(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateMicrositeAppointmentScheduler(
    id: number,
    payload: IMicrositeAppointmentSchedulerDto
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/micro-site/appointment-scheduler`;
    try {
      const response: typeof AxiosResponse = await this.instance.put(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateHoursOfOperationOfOperation(
    id: number,
    payload: IHoursOfOperationOfOperationDto
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/micro-site/hours-of-operation`;
    try {
      const response: typeof AxiosResponse = await this.instance.put(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateMicrositeBannerImage(
    id: number,
    payload: IMicrositeBannerDto
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/micro-site/banner`;
    try {
      const response: typeof AxiosResponse = await this.instance.put(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteMicrositeBannerImage(
    id: number
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/micro-site/banner`;
    try {
      const response: typeof AxiosResponse = await this.instance.delete(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getReviewLinkShortlink(
    id: number
  ): Promise<IResponse<IMicrositeDetail>> {
    const url = `/orders/${id}/review-link/shortlinks`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateReviewLinkShortlink(
    id: number,
    payload: IMicrositeDetail
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/review-link/shortlinks/main`;
    try {
      const response: typeof AxiosResponse = await this.instance.put(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateReviewLinkLogo(
    id: number,
    payload: IMicrositeBannerDto
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/review-link/business-logo`;
    try {
      const response: typeof AxiosResponse = await this.instance.put(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteReviewLinkLogo(id: number): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/review-link/business-logo`;
    try {
      const response: typeof AxiosResponse = await this.instance.delete(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createReviewLinkShortlink(
    id: number,
    payload: IMicrositeDetail
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/review-link/shortlinks`;
    try {
      const response: typeof AxiosResponse = await this.instance.post(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteReviewLinkShortlink(
    id: number,
    shortlink: string
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/review-link/shortlinks/${shortlink}`;
    try {
      const response: typeof AxiosResponse = await this.instance.delete(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getReviewLinkViewOptions(
    id: number
  ): Promise<IResponse<IMicrositeDetail>> {
    const url = `/orders/${id}/review-link/view-options`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getReviewLinkSettings(
    id: number,
    params: IRequestParams
  ): Promise<IResponse<IMicrositeDetail>> {
    const urlParams = qs.stringify(pickBy(params, identity));
    const url = `/orders/${id}/review-link/view-settings?${urlParams}`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getReviewLinkSites(
    id: number,
    params: IRequestParams
  ): Promise<IResponse<IMicrositeDetail>> {
    const urlParams = qs.stringify(pickBy(params, identity));
    const url = `/orders/${id}/review-link/review-sites?${urlParams}`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateReviewLinkSites(
    id: number,
    siteId: number,
    payload: IMicrositeDetail
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/review-link/review-sites/${siteId}/priority`;
    try {
      const response: typeof AxiosResponse = await this.instance.put(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateReviewLinkSettings(
    id: number,
    payload: IMicrositeDetail
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/review-link/view-settings`;
    try {
      const response: typeof AxiosResponse = await this.instance.put(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateInternalFeedbackStatus(
    id: number,
    payload: IMicrositeDetail
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/review-link/internal-feedback/set`;
    try {
      const response: typeof AxiosResponse = await this.instance.put(
        url,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async resetInternalFeedbackStatus(
    id: number
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/review-link/internal-feedback/reset`;
    try {
      const response: typeof AxiosResponse = await this.instance.put(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getWhiteLabelSettings(): Promise<IResellerConfiguration> {
    const url = `/configurations/reseller`;

    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getGoogleOAuthURL(
    id: number,
    profileId: number,
    siteId: number
  ): Promise<IResponse<null | number>> {
    const url = `/oauth/google?oid=${id}`;

    try {
      const response: typeof AxiosResponse = await this.instance.post(url, {
        profile_id: profileId,
        site_id: siteId,
      });
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateGoogleReviewResponse(
    payload: IGoogleReviewResponse
  ): Promise<IResponse<null | number>> {
    const url = `/reviews/${payload.reviewId}/response?oid=${payload.id}`;

    try {
      const response: typeof AxiosResponse = await this.instance.put(url, {
        profile_id: payload.profile_id,
        site_id: payload.site_id,
        reply: payload.reply,
      });
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getReviewByID(
    reviewID: number,
    id: number
  ): Promise<IResponse<null | number>> {
    const url = `/reviews/${reviewID}?oid=${id}`;
    try {
      const response: typeof AxiosResponse = await this.instance.get(url);
      const { data } = response;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getPaymentInfo(id: number): Promise<IResponse<IMicrositeDetail>> {
    const url = `/orders/${id}/payment/information`;
    const response: typeof AxiosResponse = await this.instance.get(url);
    const { data } = response;
    return data;
  }

  async postPaymentMethodDefault(
    id: number,
    payload: any
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/payment/default`;
    const response: typeof AxiosResponse = await this.instance.post(
      url,
      payload
    );
    const { data } = response;
    return data;
  }

  async deletePaymentMethod(
    id: number,
    token: string
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/payment/${token}`;
    const response: typeof AxiosResponse = await this.instance.delete(url);
    const { data } = response;
    return data;
  }

  async getYextUrl(id: number): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/yext/sso`;
    const response: typeof AxiosResponse = await this.instance.get(url);
    const { data } = response;
    return data;
  }

  async getPlans(): Promise<IResponse<null | number>> {
    const url = `/plans`;
    const response: typeof AxiosResponse = await this.instance.get(url);
    const { data } = response;
    return data;
  }

  async createLocationSubscription(
    id: number,
    payload: ILocationSubscriptionDto
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/location`;
    const response: typeof AxiosResponse = await this.instance.post(
      url,
      payload
    );
    const { data } = response;
    return data;
  }

  async createProviderSubscription(
    id: number,
    payload: IProviderSubscriptionDto
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${id}/provider`;
    const response: typeof AxiosResponse = await this.instance.post(
      url,
      payload
    );
    const { data } = response;
    return data;
  }

  async cancelSubscription(
    oid: number,
    id: number
  ): Promise<IResponse<null | number>> {
    const url = `/orders/${oid}/cancel/${id}`;
    const response: typeof AxiosResponse = await this.instance.put(url);
    const { data } = response;
    return data;
  }
}
