import { isArray } from "lodash";
import parsePhoneNumber, { isPossiblePhoneNumber } from "libphonenumber-js";

export const ParsePhoneNumber = (phone: string) => {
  const phoneNumber = parsePhoneNumber(phone, "US");
  if (phoneNumber) {
    return phoneNumber.number;
  }
  return phone;
};

export const PossiblePhoneNumber = (phone: string) => {
  return isPossiblePhoneNumber(phone, "US");
};

export const ReviewGradeStyle = (grade: string) => {
  switch (grade) {
    case "A":
    case "B":
      return {
        background: "border-[#AED2A8] bg-[#F1FFEF]",
        content: "text-[#0B8A00]",
      };
    case "C":
    case "D":
      return {
        background: "border-[#FFDE6A] bg-[#FFFBF1]",
        content: "text-[#DC7300]",
      };
    case "E":
    case "F":
      return {
        background: "border-[#FFD7D7] bg-[#FFEEEE]",
        content: "text-[#EB5757]",
      };
    default:
      return {
        background: "border-[#DBDBDB] bg-[#F8F7F7]",
        content: "text-black",
      };
  }
};

export const ReviewDistributionColors = [
  "#207FA2",
  "#9B3FF7",
  "#EC6A2C",
  "#C41200",
  "#2980B9",
  "#27AE60",
  "#D7E4E8",
];

export const ErrorMessage = (response: any) => {
  switch (true) {
    case isArray(response.message) && response.message.length > 0:
      return response.message[0];

    case Object.prototype.hasOwnProperty.call(response, "message") &&
      response.message.length > 0:
      return response.message;

    case Object.prototype.hasOwnProperty.call(response, "error"):
      return response.error;

    default:
      return "Something went wrong, please try again";
  }
};

export const stackedText = {
  id: "stackedText",
  afterDatasetsDraw(chart: any, arg: any, option: any) {
    const {
      ctx,
      chartArea: { top, width, height },
    } = chart;
    ctx.save();

    const fontHeight = 30;
    const smallerFontHeight = 15;
    ctx.font = `bolder ${fontHeight}px Inter`;
    ctx.fillStyle = "#4F4F4F";
    ctx.textAlign = "center";
    ctx.fillText(option.text, width / 2, height / 2);

    ctx.restore();

    ctx.font = `200 ${smallerFontHeight}px Inter`;
    ctx.fillStyle = "#4F4F4F";
    ctx.textAlign = "center";
    ctx.fillText("Reviews", width / 2, height / 2 + top + fontHeight - 10);

    ctx.restore();
  },
};

export const stackedPercentText = {
  id: "stackedPercentText",
  afterDatasetsDraw(chart: any, arg: any, option: any) {
    const {
      ctx,
      chartArea: { top, width, height },
    } = chart;
    ctx.save();

    const fontHeight = 24;
    const smallerFontHeight = 16;
    ctx.font = `bolder ${fontHeight}px Inter`;
    ctx.fillStyle = "#0B8A00";
    ctx.textAlign = "center";
    ctx.fillText(`${option.percent}%`, width / 2, height / 2);

    ctx.restore();

    ctx.font = `200 ${smallerFontHeight}px Inter`;
    ctx.fillStyle = "#4F4F4F";
    ctx.textAlign = "center";
    ctx.fillText("Positive", width / 2, height / 2 + top + fontHeight - 5);

    ctx.restore();
  },
};

export const radiusBackground = {
  id: "radiusBackground",
  beforeDraw(chart: any, arg: any, option: any) {
    const { ctx } = chart;
    const { top, left, width, height } = chart.chartArea;
    const x = left + width / 2;
    const y = top + height / 2;
    const outerRadius = chart._metasets[0].data[0].outerRadius;
    const innerRadius =
      chart._metasets[chart._metasets.length - 1].data[0].innerRadius;
    ctx.beginPath();
    ctx.arc(x, y, innerRadius, 0, Math.PI * 2, false);
    ctx.arc(x, y, outerRadius, 0, Math.PI * 2, true);
    ctx.fillStyle = option.backgroundColor;
    ctx.fill();
  },
};

export const ListHours = [
  ...Array.from(Array(24).keys()).map((i) => (i < 10 ? `0${i}` : `${i}`)),
];

export const ListMinutes = [
  ...Array.from(Array(60).keys()).map((i) => (i < 10 ? `0${i}` : `${i}`)),
];

export const States = [
  { name: "Alabama", code: "AL", timeZone: "US/Central" },
  { name: "Alaska", code: "AK", timeZone: "US/Alaska" },
  { name: "Arizona", code: "AZ", timeZone: "US/Arizona" },
  { name: "Arkansas", code: "AR", timeZone: "US/Central" },
  { name: "California", code: "CA", timeZone: "US/Eastern" },
  { name: "Colorado", code: "CO", timeZone: "US/Mountain" },
  { name: "Connecticut", code: "CT", timeZone: "US/Eastern" },
  { name: "Delaware", code: "DE", timeZone: "US/Eastern" },
  { name: "District of Columbia", code: "DC", timeZone: "US/Eastern" },
  { name: "Florida", code: "FL", timeZone: "US/Eastern" },
  { name: "Georgia", code: "GA", timeZone: "US/Eastern" },
  { name: "Hawaii", code: "HI", timeZone: "US/Hawaii" },
  { name: "Idaho", code: "ID", timeZone: "US/Pacific" },
  { name: "Illinois", code: "IL", timeZone: "US/Central" },
  { name: "Indiana", code: "IN", timeZone: "US/Eastern" },
  { name: "Iowa", code: "IA", timeZone: "US/Central" },
  { name: "Kansas", code: "KS", timeZone: "US/Central" },
  { name: "Kentucky", code: "KY", timeZone: "US/Central" },
  { name: "Louisiana", code: "LA", timeZone: "US/Central" },
  { name: "Maine", code: "ME", timeZone: "US/Eastern" },
  { name: "Maryland", code: "MD", timeZone: "US/Eastern" },
  { name: "Massachusetts", code: "MA", timeZone: "US/Eastern" },
  { name: "Michigan", code: "MI", timeZone: "US/Central" },
  { name: "Minnesota", code: "MN", timeZone: "US/Central" },
  { name: "Mississippi", code: "MS", timeZone: "US/Eastern" },
  { name: "Missouri", code: "MO", timeZone: "US/Central" },
  { name: "Montana", code: "MT", timeZone: "US/Mountain" },
  { name: "Nebraska", code: "NE", timeZone: "US/Eastern" },
  { name: "Nevada", code: "NV", timeZone: "US/Eastern" },
  { name: "New Hampshire", code: "NH", timeZone: "US/Eastern" },
  { name: "New Jersey", code: "NJ", timeZone: "US/Eastern" },
  { name: "New Mexico", code: "NM", timeZone: "US/Mountain" },
  { name: "New York", code: "NY", timeZone: "US/Eastern" },
  { name: "North Carolina", code: "NC", timeZone: "US/Eastern" },
  { name: "North Dakota", code: "ND", timeZone: "US/Mountain" },
  { name: "Ohio", code: "OH", timeZone: "US/Eastern" },
  { name: "Oklahoma", code: "OK", timeZone: "US/Central" },
  { name: "Oregon", code: "OR", timeZone: "US/Pacific" },
  { name: "Pennsylvania", code: "PA", timeZone: "US/Eastern" },
  { name: "Rhode Island", code: "RI", timeZone: "US/Eastern" },
  { name: "South Carolina", code: "SC", timeZone: "US/Eastern" },
  { name: "South Dakota", code: "SD", timeZone: "US/Central" },
  { name: "Tennessee", code: "TN", timeZone: "US/Eastern" },
  { name: "Texas", code: "TX", timeZone: "US/Central" },
  { name: "Utah", code: "UT", timeZone: "US/Mountain" },
  { name: "Vermont", code: "VT", timeZone: "US/Eastern" },
  { name: "Virgin Island", code: "VI", timeZone: "Atlantic/South_Georgia" },
  { name: "Virginia", code: "VA", timeZone: "US/Eastern" },
  { name: "Washington", code: "WA", timeZone: "US/Pacific" },
  { name: "West Virginia", code: "WV", timeZone: "US/Eastern" },
  { name: "Wisconsin", code: "WI", timeZone: "US/Central" },
  { name: "Wyoming", code: "WY", timeZone: "US/Mountain" },
];

export const InputErrorMessage = (error: any) => {
  switch (true) {
    case Object.prototype.hasOwnProperty.call(error, "required") &&
      error.required === false:
      return "This field is required";
    case Object.prototype.hasOwnProperty.call(error, "minLength") &&
      error.minLength === false:
      return `Minimum length is ${error.$params.minLength.min} characters`;
    case Object.prototype.hasOwnProperty.call(error, "isPhone") &&
      error.isPhone === false:
      return "Please enter a valid phone number";
    case Object.prototype.hasOwnProperty.call(error, "isZIP") &&
      error.isZIP === false:
      return "Please enter a valid ZIP code";
    case Object.prototype.hasOwnProperty.call(error, "url") &&
      error.url === false:
      return "Please enter a valid URL";
    case Object.prototype.hasOwnProperty.call(error, "email") &&
      error.email === false:
      return "Please enter a valid email address";

    default:
      return "";
  }
};
