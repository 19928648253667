<template>
  <button
    :id="id"
    class="px-8 py-3 text-center rounded-md text-white bg-primary font-semibold"
    :class="[
      disabled ? 'opacity-50' : 'hover:cursor-pointer hover:bg-[#15536A]',
      block ? 'w-full' : '',
      classes,
    ]"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: "",
    },
  },
};
</script>
