<template>
  <div class="my-4 px-1">
    <p class="mb-1" :class="labelSize">{{ label }}</p>
    <div
      class="px-4 py-2 border rounded flex gap-4 items-center flex-1 overflow-hidden"
      :class="[
        hover ? 'ring ring-orange/25' : '',
        focus ? 'border-orange' : 'border-lineGray',
        `bg-${background}`,
      ]"
    >
      <input
        :type="type"
        :class="`border-none focus:ring-transparent p-0 m-0 flex-1 bg-${background}`"
        :disabled="disabled"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
        @focus="focus = true"
        @blur="handleBlur"
        @keyup.enter="onEnter"
        v-model="inputVal"
        :placeholder="placeholder"
        :readonly="readonly"
        :required="required"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    icon: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    onEnter: {
      type: Function,
    },
    onBlur: {
      type: Function,
    },
    background: {
      type: String,
      default: "bgGray",
    },
    labelSize: {
      type: String,
      default: "",
    },
    value: null,
  },
  data() {
    return {
      hover: false,
      focus: false,
      errorMessage: null,
    };
  },
  methods: {
    rulesChecker() {
      if (this.rules && this.rules.length > 0) {
        for (let i = 0; i < this.rules.length; i++) {
          if (this.rules[i](this.inputVal) !== true) {
            this.errorMessage = this.rules[i](this.inputVal);
            return;
          } else {
            this.errorMessage = null;
          }
        }
      }
    },
    handleBlur() {
      this.focus = false;
      this.onBlur && this.onBlur();
    },
  },

  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.required && this.rules.length === 0) {
          val ? (this.errorMessage = false) : (this.errorMessage = "Required");
        }
        this.$emit("input", val);
      },
    },
  },
};
</script>
