<template>
  <textarea
    :disabled="disabled"
    :placeholder="placeholder"
    v-model="inputVal"
    class="w-full border border-lineGray rounded px-4 py-3 focus:border-orange hover:ring hover:ring-orange/25 focus:ring-0"
    :rows="rows"
    :class="classStyle"
  />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    classStyle: {
      type: String,
      default: "",
    },
    rows: {
      type: String,
      default: "5",
    },
    value: null,
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
