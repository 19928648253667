import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { preLoginGuard, postLoginGuard } from "@/auth/authGuard";
import Store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/subscription/:oid",
    component: () => import("../views/core/Layout.vue"),
    redirect: () => {
      const selected_order_id = Vue.$cookies.get("selected_order_id");
      return `/subscription/${selected_order_id}/dashboard`;
    },
    children: [
      {
        name: "Report Card",
        path: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "Reviews",
        path: "reports/reviews",
        component: () => import("@/views/Reviews.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "Internal Feedback",
        path: "reports/internal-feedback",
        component: () => import("@/views/InternalFeedback.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "Invite Performance & History",
        path: "reports/invite-performance",
        component: () => import("@/views/InvitePerformance.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "Blocked Invitations",
        path: "reports/blocked-invitations",
        component: () => import("@/views/BlockedInvitations.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "User & Subscriptions",
        path: "settings/user-subscriptions",
        component: () => import("@/views/UserSubscriptions.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "Manage Subscriptions",
        path: "settings/subscriptions",
        component: () => import("@/views/Subscriptions.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "New Subscription",
        path: "settings/subscriptions/new",
        component: () => import("@/views/SubscriptionNew.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "Trend Report",
        path: "reports/trend-report",
        component: () => import("@/views/TrendReport.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "Review Invite Settings",
        path: "customize/review-invite-settings",
        component: () => import("@/views/ReviewInviteSettings.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "Review Link",
        path: "customize/review-link",
        component: () => import("@/views/ReviewLink.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "Review Reel",
        path: "customize/review-reel",
        component: () => import("@/views/ReviewReel.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "Review Responses",
        path: "customize/review-responses",
        component: () => import("@/views/ReviewResponses.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "Review Details",
        path: "customize/review-responses/:reviewId/response",
        component: () => import("@/views/ReviewResponseDetail.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "Manage Microsite",
        path: "customize/microsite",
        component: () => import("@/views/Microsite.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "Listings Manager",
        path: "customize/listings-manager",
        component: () => import("@/views/ListingsManager.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "Billing",
        path: "settings/billing",
        component: () => import("@/views/Billing.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "Send Invite",
        path: "send-invite",
        component: () => import("@/views/SendInvite.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "Customer Support",
        path: "customer-support",
        component: () => import("@/views/CustomerSupport.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "Referral",
        path: "referral",
        component: () => import("@/views/Referral.vue"),
        beforeEnter: postLoginGuard,
      },
      {
        name: "Account Details",
        path: "settings/account-details",
        component: () => import("@/views/AccountDetails.vue"),
        beforeEnter: postLoginGuard,
      },
    ],
  },
  {
    path: "/auth",
    component: () => import("../views/auth/core/Layout.vue"),
    children: [
      {
        name: "Login",
        path: "login",
        component: () => import("@/views/auth/Login.vue"),
        beforeEnter: preLoginGuard,
      },
    ],
  },
  {
    path: "/forgot-password",
    component: () => import("../views/auth/core/Layout.vue"),
    children: [
      {
        name: "Forgot Password",
        path: "",
        component: () => import("@/views/ForgotPassword.vue"),
        beforeEnter: preLoginGuard,
      },
    ],
  },
  {
    path: "/reset-password",
    component: () => import("../views/auth/core/Layout.vue"),
    children: [
      {
        name: "Reset Password",
        path: "",
        component: () => import("@/views/ResetPassword.vue"),
        beforeEnter: preLoginGuard,
      },
    ],
  },
  {
    path: "*",
    beforeEnter: preLoginGuard,
    redirect: "/auth/login",
  },
];

const router = new VueRouter({
  routes,
});

router.afterEach(() => {
  Store.commit("setLoading", false);
});
export default router;
